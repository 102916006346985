<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="card-shadow">
          <v-card-title>
            <span class="underline--magical">Get a free Quote</span>
          </v-card-title>

          <v-card-text>
            <span> Let us know what services Bonsai can assist you with? </span>
            <v-autocomplete
              class="mt-2"
              v-model="selectedProducts"
              :items="productItems"
              outlined
              dense
              chips
              small-chips
              label="Our products (optional)"
              item-text="itemTitle"
              item-value="itemTitle"
              hide-details
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  small
                  class="my-2"
                  color="primary"
                  v-on:click="data.select"
                  v-on:click:close="remove(data.item)"
                >
                  {{ data.item.itemTitle }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar small>
                  <v-avatar color="grey lighten-2">
                    <v-fade-transition>
                      <v-icon>mdi-radiobox-marked</v-icon>
                    </v-fade-transition>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.itemTitle
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.productTitle
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contact.fullname"
                  outlined
                  placeholder=" "
                  label="Full name"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contact.cellphone"
                  outlined
                  placeholder=" "
                  label="Cellphone"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contact.email"
                  outlined
                  placeholder=" "
                  label="Email (optional)"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="" class="d-flex justify- align-center">
                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      block
                      color="primary"
                      class="normal-caps elevation-15"
                      v-on:click="contact.loading = true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Get my Quote &nbsp;
                      <v-icon color="primary lighten-1" small
                        >mdi-arrow-right-thick</v-icon
                      >
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-title>
                        <span class="underline--magical"
                          >Your request has been received.</span
                        >
                        <v-spacer></v-spacer>
                        <v-btn icon v-on:click="dialog.value = false"
                          ><v-icon>mdi-close</v-icon></v-btn
                        >
                      </v-card-title>
                      <v-card-text>
                        <p>
                          Thank you for providing your details. One of our
                          friendly agents will be in touch shortly to assist you
                          with your request.
                        </p>
                        <span class="mb-2 subtitle-1 font-weight-medium"
                          >Did you know?</span
                        >
                        <p>
                          When you take out or move your policy to Bonsai, you
                          will qualify for an automatic monthly cashback. Here
                          is how it works:
                        </p>
                        <ul>
                          <li>
                            You take out a new policy or transfer an existing
                            policy to Bonsai.
                          </li>
                          <li>
                            Our system will calculate the cashback based on the
                            type of policy and the premium amount.
                          </li>
                          <li>
                            We allocate the cashback to a virtual card that you
                            can use to pay for anything on the internet.
                          </li>
                          <li>
                            You can also leave your cashback to grow at 6% per
                            year.
                          </li>
                        </ul>
                        <br />
                        <p>
                          As long as you are a client of Bonsai, you will be
                          rewarded for improving your financial wellness.
                        </p>
                      </v-card-text>
                      <v-card-text>
                        <v-row justify="center">
                          <v-col cols="auto">
                            <lord-icon
                              v:bind="$attrs"
                              :src="`https://cdn.lordicon.com/zczmziog.json`"
                              trigger="loop"
                              :colors="`primary:#121331,secondary:${$vuetify.theme.themes.light.primary}`"
                              stroke="20"
                              style="width: 100px; height: 100px"
                            >
                            </lord-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text></v-card-text>
                      <v-card-actions>
                        <v-spacer> </v-spacer>
                        <v-btn
                          depressed
                          color="primary"
                          small
                          outlined
                          class="normal-caps mb-3 mr-2"
                          to="/"
                        >
                          Done &nbsp;
                          <v-icon color="primary lighten-1" small
                            >mdi-check</v-icon
                          >
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <small>
                  We understand how important your privacy is and will protect
                  your personal information. By providing your personal
                  information you are agreeing that we may process it to furnish
                  you with a quote. Also, note that some advisors work under
                  supervision. This is to ensure that you’ll get nothing but
                  awesome service, all of the time. It is important to provide
                  us with correct information, otherwise, it could affect the
                  outcome of your claim.
                </small>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import products from "@/library/products";
export default {
  props: {
    item: String,
  },
  data: function () {
    return {
      selectedProducts: [],
      products: products,
      productItems: [],
      contact: {
        fullname: " ",
        cellphone: " ",
        email: " ",
        loading: false,
      },
    };
  },
  methods: {},
  computed: {},
  mounted: function () {
    for (let pIdx = 0; pIdx < this.products.length; pIdx++) {
      const product = this.products[pIdx];
      if (!product.items) {
        continue;
      }
      for (let itemIdx = 0; itemIdx < product.items.length; itemIdx++) {
        const item = product.items[itemIdx];

        this.productItems.push({
          productTitle: product.title,
          itemTitle: item.title,
        });
      }
    }

    this.selectedProducts.push(this.item);
  },
};
</script>

<style>
</style>